<template>
  <TransitionRoot as="template" :show="props.isShow">
    <Dialog as="div" class="relative z-40" @close="emit('closed')">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-90 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-2 text-center"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="text-white relative transform overflow-hidden rounded-lg pb-4 pt-5 text-left shadow-xl transition-all sm:my-2 sm:w-full sm:max-w-sm sm:p-6 md:max-w-md lg:max-w-2xl"
            >

              <div class="flex flex-col items-center">
                <slot name="body"></slot>
              </div>

              <div v-if="showFooter">
                <button
                  type="button"
                  @click="emit('closed')"
                  class="block py-2 px-6 font-medium mx-auto focus:outline-none text-white border items-center rounded-lg mt-8 focus:z-10"
                >
                  <i class="fa-solid fa-xmark mx-1 text-lg"></i>
                  Close
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref } from "vue";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import _ from 'lodash'
const emit = defineEmits(["closed"]);
const props = defineProps({
  isShow: {
    type: Boolean,
    default: false,
  },
  showFooter: { type: Boolean, default: false}
});

</script>
